/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import {  } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"New page"}>
      </Layout>
    </ThemeWrapper>
  )
}